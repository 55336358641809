import React, { ReactNode } from "react";
import { CustomButton } from "../../index";
import CustomModal from "../CustomModal";
import "./StatusModal.scss";

const StatusModal = ({
  show,
  onHide,
  title,
  subTitle,
  icon,
  btntext,
}: {
  show?: boolean;
  onHide?: any;
  title?: string;
  subTitle?: any;
  icon?: ReactNode;
  btntext?: string;
}) => {
  return (
    <CustomModal
      show={show}
      onHide={onHide}
      title={title}
      subTitle={subTitle}
      icon={icon}
      className="status_modal"
      closeBtnHide
    >
      <div className="status_modal_inner">
        <CustomButton
          text={btntext}
          className="w-100"
          onClick={() => onHide()}
        />
      </div>
    </CustomModal>
  );
};

export default StatusModal;
