import { ReactNode } from "react";
import Modal from "react-bootstrap/Modal";
import { CloseIcon } from "../../../Assets/Icon/svg/SvgIcons";
import "./CustomModal.scss";

const CustomModal = ({
  show,
  onHide,
  children,
  className,
  title,
  subTitle,
  icon,
  closeBtnHide,
}: {
  show?: boolean;
  onHide?: any;
  children?: ReactNode;
  className?: string;
  title?: string | ReactNode;
  subTitle?: string | ReactNode;
  closeButton?: any;
  icon?: ReactNode | boolean;
  closeBtnHide?: boolean;
}) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      className={`custom-modal ${className ? className : ""}`}
    >
      {closeBtnHide ? (
        ""
      ) : (
        <button onClick={onHide} className="custom-modal__action">
          <CloseIcon />
        </button>
      )}
      {(title || subTitle) && (
        <div className="custom-modal__header">
          {icon && <span className="custom-modal__header_icon">{icon}</span>}
          {title && <h2>{title}</h2>}
          {subTitle && <p>{subTitle}</p>}
        </div>
      )}
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};

export default CustomModal;
