import React, { useEffect } from "react";
import { LineWave } from "react-loader-spinner";
import "./Loader.scss";
import { loader } from "../../../Redux/Slices/loader.slice";
import { useDispatch, useSelector } from "react-redux";

const Loader = () => {
  const dispatch: any = useDispatch();
  /**GET STATES FROM STORE */
  const isLoading: any = useSelector((state: any) => state.loader.isLoading)
  useEffect(() => {
    dispatch(loader(false));
  }, [dispatch]);
  return (<>
    {isLoading && <div className="overlayloader">
      <span className="overlayloader__inner">
        <LineWave
          height="150"
          width="150"
          color="#6d8afa"
          ariaLabel="line-wave"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          firstLineColor=""
          middleLineColor=""
          lastLineColor=""
        />
      </span>
    </div>}
  </>
  );
};

export default Loader;
