import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  walletAddress: "",
  walletType: "",
  userDetails: {},
  authToken: "",
  kycToken: "",
  otpRemainingTime: "",
  otpRemainingTimePhone: "",
  isAuthRedirect: true,
  isUserVerified: false,
  usdcDecimals: "",
  idSocket: "",
  adminSocket: "",
  kycSocket: "",
  propertySocket: "",
  addressSocket: "",
  adminDashboardSocket: "",
  selectedToken: {
    value: {
      uid: "",
      token: "",
    },
  },
};

/**USER DETAILS SLICE */
export const UserSlice = createSlice({
  name: "user",
  initialState,

  reducers: {
    setUserDetails: (state, param) => {
      const { payload } = param;
      state.userDetails = payload;
    },
    addAuthToken: (state, param) => {
      const { payload } = param;
      state.userDetails = { ...state.userDetails, token: payload };
    },
    setWalletAddress: (state, param) => {
      const { payload } = param;
      state.walletAddress = payload;
    },
    walletType: (state, param) => {
      const { payload } = param;
      state.walletType = payload;
    },
    authToken: (state, param) => {
      const { payload } = param;
      state.authToken = payload;
    },
    kycToken: (state, param) => {
      const { payload } = param;
      state.kycToken = payload;
    },
    setOtpRemainingTime: (state, param) => {
      const { payload } = param;
      state.otpRemainingTime = payload;
    },
    setOtpRemainingTimePhone: (state, param) => {
      const { payload } = param;
      state.otpRemainingTimePhone = payload;
    },
    isAuthRedirect: (state, param) => {
      const { payload } = param;
      state.isAuthRedirect = payload;
    },
    isUserVerified: (state, param) => {
      const { payload } = param;
      state.isUserVerified = payload;
    },
    usdcDecimals: (state, param) => {
      const { payload } = param;
      state.usdcDecimals = parseInt(payload).toString();
    },
    idSocket: (state, param) => {
      const { payload } = param;
      state.idSocket = payload;
    },
    adminSocket: (state, param) => {
      const { payload } = param;
      state.adminSocket = payload;
    },
    kycSocket: (state, param) => {
      const { payload } = param;
      state.kycSocket = payload;
    },
    propertySocket: (state, param) => {
      const { payload } = param;
      state.propertySocket = payload;
    },
    addressSocket: (state, param) => {
      const { payload } = param;
      state.addressSocket = payload;
    },
    adminDashboardSocket: (state, param) => {
      const { payload } = param;
      state.adminDashboardSocket = payload;
    },
    setSelectedToken: (state, param) => {
      const { payload } = param;
      state.selectedToken = payload;
    },

    resetUser: () => initialState,
  },
});

/**ACTIONS FOR SLICE*/
export const {
  setUserDetails,
  setWalletAddress,
  walletType,
  resetUser,
  authToken,
  kycToken,
  setOtpRemainingTime,
  setOtpRemainingTimePhone,
  isAuthRedirect,
  isUserVerified,
  usdcDecimals,
  addAuthToken,
  idSocket,
  adminSocket,
  kycSocket,
  propertySocket,
  addressSocket,
  adminDashboardSocket,
  setSelectedToken,
} = UserSlice.actions;
