import packageJson from "../../package.json";
import { LENGTH } from "../Constant";

const CryptoJS = require("crypto-js");

// Resets cache
export const versionManager = async () => {
  try {
    const version = packageJson.version;
    const react_version = localStorage.getItem("react_version");

    if (!react_version || (react_version && version !== react_version)) {
      // resetRedux();
      localStorage.clear();
      window.location.reload();
    }
    localStorage.setItem("react_version", version);
  } catch (error) {}
};

function isPrime(number: number) {
  if (number <= 1) {
    return false;
  }

  if (number <= 3) {
    return true;
  }

  if (number % 2 === 0 || number % 3 === 0) {
    return false;
  }

  for (let i = 5; i * i <= number; i += 6) {
    if (number % i === 0 || number % (i + 2) === 0) {
      return false;
    }
  }

  return true;
}

function getPrimeNumbersInRange(start: number, end: number) {
  const primeNumbers: any = [];

  for (let number = start; number <= end; number++) {
    if (isPrime(number)) {
      primeNumbers.push(number);
    }
  }
  return primeNumbers;
}
export function getKey(value: number) {
  const SECRET_KEY2: any = process.env.REACT_APP_SECRET_KEY2;
  const SECRET_KEY3: any = process.env.REACT_APP_SECRET_KEY3;
  const SECRET_KEY5: any = process.env.REACT_APP_SECRET_KEY5;
  const SECRET_KEY8: any = process.env.REACT_APP_SECRET_KEY8;

  let encyptionKey = SECRET_KEY2?.concat(SECRET_KEY3, SECRET_KEY5, SECRET_KEY8);
  const primeNumbers: any = getPrimeNumbersInRange(1, value);
  const string = primeNumbers
    .map((number: number) => encyptionKey[number])
    .join("");
  return string;
}

export const decryptData = (data: any, string = false) => {
  try {
    const key: string = getKey(Number(LENGTH));
    const decryptData = CryptoJS.AES.decrypt(data, key);
    let stringData = decryptData.toString(CryptoJS.enc.Utf8);
    stringData = string ? stringData : JSON.parse(stringData);
    return stringData;
  } catch (error) {
    return false;
  }
};
export const encryptData = (data: any) => {
  const key: string = getKey(Number(LENGTH));
  const stringData = JSON.stringify(data);
  const encryptData = CryptoJS.AES.encrypt(stringData, key).toString();
  return encryptData;
};
/**CREATE URL FOR API CALL WITH PARAMS */
export const formatUrl = (url: string, params: any) => {
  params =
    params && Object.keys(params).length > 0
      ? `?${new URLSearchParams(params).toString()}`
      : ``;
  return `${url}${params}`;
};
export const  removePlusSign=(phoneNumber:string)=> {
  if (phoneNumber?.startsWith('+')) {
      return phoneNumber?.substring(1);
  }
  return phoneNumber;
}