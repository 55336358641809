import { createSlice } from "@reduxjs/toolkit";

const initialState: {
  theme: "light" | "dark";
  update: Boolean;
  role: Array<any>;
} = {
  theme: "light",
  update: false,
  role: [],
};

export const ThemeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    changeTheme: (state, { payload }) => {
      state.theme = payload;
    },

    setUpdate: (state, { payload }) => {
      state.update = payload;
    },

    setRole: (state, { payload }) => {
      state.role = payload;
    },

    resetTheme: () => initialState,
  },
});

export const { changeTheme, setUpdate, setRole, resetTheme } =
  ThemeSlice.actions;
