import { combineReducers } from "redux";
import { UserSlice } from "../Slices/user.slice";
import { LoaderSlice } from "../Slices/loader.slice";
import { ThemeSlice } from "../Slices/theme.slice";
import { IssuerSlice } from "../Slices/issuer.slice";

/**COMBINE ALL REDUCERS */
export const reducers = combineReducers({
  user: UserSlice.reducer,
  loader: LoaderSlice.reducer,
  theme: ThemeSlice.reducer,
  issuer : IssuerSlice.reducer
});
