// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Krona+One&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overlayloader {
  width: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  margin-top: -1px;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 9991;
  height: 102%;
  background-color: rgba(42, 46, 47, 0.8);
}
.overlayloader__inner {
  margin-right: -5.5rem;
}`, "",{"version":3,"sources":["webpack://./src/Components/UI/Loader/Loader.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,eAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,QAAA;EACA,gBAAA;EACA,SAAA;EACA,gCAAA;EACA,kBAAA;EACA,aAAA;EACA,YAAA;EACA,uCAAA;AAAF;AAEE;EACE,qBAAA;AAAJ","sourcesContent":["@import \"../../../Assets/Theme/_var.scss\";\n\n.overlayloader {\n  width: 100%;\n  position: fixed;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  top: 50%;\n  margin-top: -1px;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  text-align: center;\n  z-index: 9991;\n  height: 102%;\n  background-color: #2a2e2fcc;\n\n  &__inner {\n    margin-right: -5.5rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
