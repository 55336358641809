import { ReactNode } from "react";
import { Table } from "react-bootstrap";
import CommonHeading from "../CommonHeading/CommonHeading";
import CustomPagination from "../CustomPagination/CustomPagination";
import { NoRecord } from "../../../Assets/Icon/svg/SvgIcons";
import "./CustomTable.scss";

const CustomTable = ({
  className,
  fields,
  children,
  noRecordFound,
  title,
  pagination,
}: {
  className?: string;
  fields?: string[];
  sortbuttons?: boolean;
  children?: ReactNode;
  noRecordFound?: ReactNode;
  title?: string;
  pagination?: boolean;
}) => {
  return (
    <div className="custom-table">
      {title ? (
        <CommonHeading
          text={title}
          className="text-gradient custom-table__title"
        />
      ) : (
        ""
      )}

      <Table responsive className={`custom-table__table ${className}`}>
        {fields && (
          <thead>
            <tr>
              {fields?.map((item) => (
                <th key={item}>{item}</th>
              ))}
            </tr>
          </thead>
        )}
        <tbody>
          {children || noRecordFound || (
            <tr className="no_record text-center border-0">
              <td colSpan={fields?.length}>
                {noRecordFound || (
                  <div className="no_record_box ">
                    <NoRecord />
                    <h4>No Record Found</h4>
                  </div>
                )}
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      {pagination && (
        <div className="custom-table__pagination">
          <p>Showing data 1 to 8 of 256K entries</p>
          <CustomPagination total={300} />
        </div>
      )}
    </div>
  );
};

export default CustomTable;
