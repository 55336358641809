import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  propertyDocs: [],
  otherDocs: [],
  propertyImage: {},
  propertyTokenIcon: {},
  searchText: "",
  searchPlaceholder: "",
};

/**USER DETAILS SLICE */
export const IssuerSlice = createSlice({
  name: "issuer",
  initialState,

  reducers: {
    setPropertyDocs: (state: any, param) => {
      const { payload } = param;
      state.propertyDocs = [...state.propertyDocs, payload];
    },
    setPropertyDocsAll: (state: any, param) => {
      const { payload } = param;
      state.propertyDocs = payload;
    },
    setPropertyImage: (state: any, param) => {
      const { payload } = param;
      state.propertyImage = payload;
    },
    setPropertyTokenIcon: (state: any, param) => {
      const { payload } = param;
      state.propertyTokenIcon = payload;
    },
    setSearchText: (state: any, param) => {
      const { payload } = param;
      state.searchText = payload;
    },
    setOtherDocs: (state: any, param) => {
      const { payload } = param;
      state.otherDocs = [...state.otherDocs, payload];
    },
    setOtherDocsAll: (state: any, param) => {
      const { payload } = param;
      state.otherDocs = payload;
    },
    setSearchPlaceHolder: (state: any, param) => {
      const { payload } = param;
      state.searchPlaceholder = payload;
    },
    resetIssuer: () => initialState,
  },
});

/**ACTIONS FOR SLICE*/
export const {
  resetIssuer,
  setPropertyImage,
  setPropertyTokenIcon,
  setPropertyDocs,
  setPropertyDocsAll,
  setOtherDocs,
  setOtherDocsAll,
  setSearchText,
  setSearchPlaceHolder,
} = IssuerSlice.actions;
