import Loader from "./Loader/Loader";
import CustomButton from "./CustomButton/CustomButton";
import Sidebar from "./Sidebar/Sidebar";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import CustomTable from "./Table/CustomTable";
import Shimmer from "./Shimmer/Shimmer";
import SearchField from "./SearchField/SearchField";
import CustomPagination from "./CustomPagination/CustomPagination";
import CustomModal from "./Modal/CustomModal";
import StatusModal from "./Modal/StatusModal/StatusModal";

export {
  Loader,
  CustomButton,
  Sidebar,
  Header,
  CustomTable,
  Shimmer,
  SearchField,
  CustomPagination,
  Footer,
  CustomModal,
  StatusModal,
};
