import { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Link } from "react-scroll";
import { Container } from "react-bootstrap";
// import CustomButton from "../CustomButton/CustomButton";
import logo from "../../../Assets/Icon/logo.png";
import CustomButton from "../CustomButton/CustomButton";
import "./Header.scss";

const Header = ({className}: {className?: string}) => {
  const navigate = useNavigate();
  const [scroll, setScroll] = useState(false);

  const [text, setText] = useState(
    window.innerWidth < 767 ? "Referral" : "Become a Referral Partner"
  );

  useEffect(() => {
    const handleResize = () => {
      setText(
        window.innerWidth < 767 ? "Referral" : "Become a Referral Partner"
      );
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 10);
    });
  }, []);

  return (
    <header className={`header ${className} ${scroll ? "header__scrolled" : ""}`}>
      <Container>
        <div className="header__logo">
          <NavLink to="/">
            <img src={logo} alt="logo" />
          </NavLink>
        </div>
        <div className="header__actions">
          <CustomButton
            className="outline-btn"
            text={text}
            onClick={(event: any) => navigate("/referral")}
          />

          <Link
            activeClass="active"
            to="joinwaitlist"
            spy={true}
            offset={-70}
            duration={500}
            className="custom-button cursor-pointer"
            onClick={() => {
              navigate("/" ,{replace :true});
            }}
          >
            Join the Waitlist
          </Link>
        </div>
      </Container>
    </header>
  );
};

export default Header;
