import { motion } from "framer-motion";
import { Link, NavLink } from "react-router-dom";
import {
  DashboardIcon,
  FundsIcon,
  StakeNewIcon,
} from "../../../Assets/Icon/svg/SvgIcons";
import logo from "../../../Assets/Icon/logo.svg";
import "./Sidebar.scss";

type propTypes = {
  active?: boolean;
  handleActive?: () => void;
};
const Sidebar = (props: propTypes) => {
  const links = [
    {
      icon: <DashboardIcon />,
      text: "Marketplace",
      link: "/marketplace",
    },

    {
      icon: <StakeNewIcon />,
      text: "Staking",
      link: "/staking",
    },

    {
      icon: <FundsIcon />,
      text: "Affiliate",
      link: "/affiliate",
    },

    {
      icon: <StakeNewIcon />,
      text: "My Components",
      link: "/my-component",
    },
  ];

  return (
    <>
      <div
        onClick={props.handleActive}
        className={`overlay ${props.active ? "active" : ""} d-xl-none`}
      />
      <motion.div
        className="sidebar_bg"
        animate={{
          ...(props.active
            ? {
                width: window.screen.availWidth < 575 ? "100%" : "350px",
                borderRadius: "0",
              }
            : {
                width: "0",
                borderRadius: "0 100% 100% 0",
              }),
        }}
        transition={{
          type: "tween",
          duration: props.active ? 0.8 : 0.3,
          ease: !props.active ? "backIn" : "backOut",
        }}
      />
      <motion.aside
        className="sidebar"
        animate={{
          transform: props.active ? "translateX(0)" : "translateX(-100%)",
        }}
        transition={{
          type: "spring",
          duration: 0.2,
        }}
      >
        <Link
          to="/marketplace"
          className="sidebar__logo"
          onClick={props.handleActive}
        >
          <img src={logo} alt="logo" />
        </Link>
        <div className="sidebar__inner">
          <ul className="sidebar__inner__top">
            {links.map((item) => (
              <li key={item.link}>
                <NavLink to={item.link} onClick={props.handleActive}>
                  <span>{item.icon}</span>
                  {item.text}
                </NavLink>
              </li>
            ))}
          </ul>

          <div className="sidebar__inner__bottom">
            <p>&copy; {new Date().getFullYear()} GorillaDeFi.</p>
          </div>
        </div>
      </motion.aside>
    </>
  );
};

export default Sidebar;
