import React from "react";
import "./CommonHeading.scss";

const CommonHeading = ({
  text,
  className,
}: {
  text?: string;
  className?: string;
}) => {
  return (
    <h2 className={`common-heading ${className ? className : ""}`}>{text}</h2>
  );
};

export default CommonHeading;
