// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Krona+One&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes shimmer {
  from {
    left: -20%;
    opacity: 0.4;
  }
  to {
    left: -70%;
    opacity: 0.8;
  }
}
.shimmer {
  position: relative;
  overflow: hidden;
  border-radius: 0.8rem;
}
.shimmer::after {
  content: "";
  width: 200%;
  height: 100%;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.4));
  display: block;
  border-radius: inherit;
  position: absolute;
  top: 0;
  left: 0;
  animation: shimmer 1s ease-in-out infinite alternate;
}`, "",{"version":3,"sources":["webpack://./src/Components/UI/Shimmer/Shimmer.scss"],"names":[],"mappings":"AAEA;EACE;IACE,UAAA;IACA,YAAA;EAAF;EAGA;IACE,UAAA;IACA,YAAA;EADF;AACF;AAIA;EACE,kBAAA;EACA,gBAAA;EACA,qBAAA;AAFF;AAIE;EACE,WAAA;EACA,WAAA;EACA,YAAA;EACA,sFAAA;EACA,cAAA;EACA,sBAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,oDAAA;AAFJ","sourcesContent":["@import \"../../../Assets/Theme/_var.scss\";\n\n@keyframes shimmer {\n  from {\n    left: -20%;\n    opacity: 0.4;\n  }\n\n  to {\n    left: -70%;\n    opacity: 0.8;\n  }\n}\n\n.shimmer {\n  position: relative;\n  overflow: hidden;\n  border-radius: 0.8rem;\n\n  &::after {\n    content: \"\";\n    width: 200%;\n    height: 100%;\n    background: linear-gradient(90deg, rgba($white, 0.1), rgba($white, 0.4));\n    display: block;\n    border-radius: inherit;\n    position: absolute;\n    top: 0;\n    left: 0;\n    animation: shimmer 1s ease-in-out infinite alternate;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
